<script setup lang="ts">
import { useFocus, useVModel } from '@vueuse/core';
import { computed, ref, toRef, watch, nextTick } from 'vue';
import KLoading from '@/shared/kit/KLoading.vue';
import KChip from '@/shared/kit/chip/KChip.vue';
import FieldDropdown from '@/shared/kit/field/FieldDropdown.vue';
import KDropdownTarget from '@/shared/kit/field/KDropdownTarget.vue';
import KList from '@/shared/kit/list/KList.vue';
import KListItem from '@/shared/kit/list/KListItem.vue';
import type { InternalItem } from '@/shared/compositions/useItems';
import { useSelect } from '@/shared/compositions/useSelect';
import { useTranslation } from '@/shared/compositions/useTranslation';

const presets = {
  eq: (a: any, b: any) => a === b,
  contains: (a: string, b: string) => a.indexOf(b) !== -1,
  startsWith: (a: string, b: string) => a.lastIndexOf(b, 0) === 0,
};

type FilterPreset = keyof typeof presets;
type FilterFunction = (item: any, searchTerm: string, idx?: number) => boolean;
type Filter = boolean | FilterPreset | FilterFunction;

type DataKeyAccessorFn = (item: unknown) => unknown;
type DataKeyAccessor = string | DataKeyAccessorFn;
const dataValue = (dataItem: unknown, field?: DataKeyAccessor): unknown => {
  if (typeof field === 'function') return field(dataItem);
  if (dataItem == null) return dataItem;
  if (
    typeof field === 'string' &&
    typeof dataItem === 'object' &&
    field in dataItem!
  )
    return (dataItem as any)![field];

  return dataItem;
};

type TextAccessorFn = (item: unknown) => string;
type TextAccessor = string | TextAccessorFn;
const dataText = (dataItem: unknown, textField?: TextAccessor): string => {
  const value = dataValue(dataItem, textField);
  return value == null ? '' : String(value);
};

function normalizeFilter(
  filter: Filter,
  textField?: TextAccessor,
): FilterFunction | null {
  if (filter === false) return null;
  if (typeof filter === 'function') return filter;

  const filterPreset = presets[filter === true ? 'startsWith' : filter || 'eq'];
  return (item: unknown, searchTerm: string) => {
    let textValue = dataText(item, textField);
    return filterPreset(textValue.toLowerCase(), searchTerm.toLowerCase());
  };
}

const props = withDefaults(
  defineProps<{
    modelValue: any;
    filterString?: string;
    options?: any[] | ReadonlyArray<any>;
    itemTitle?: string;
    itemValue?: string;
    returnObject?: boolean;
    filter?: Filter;
    multiple?: boolean;
    chips?: boolean;
    hideSelected?: boolean;
    placeholder?: string;
    disabled?: boolean;
    loading?: boolean;
    needMore?: boolean;
    creatable?: boolean;
  }>(),
  {
    disabled: false,
    returnObject: false,
    multiple: false,
    filter: false,
    loading: false,
    chips: (props) => {
      return props.multiple || false;
    },
    hideSelected: (props) => {
      return props.multiple || false;
    },
  },
);
const emit = defineEmits<{
  (e: 'update:modelValue', v: any): void;
  (e: 'update:filterString', v: string): void;
  (e: 'clickMore'): void;
}>();

const { t } = useTranslation('common');

const inputRef = ref<HTMLInputElement>();
const { focused } = useFocus(inputRef, { focusVisible: true });

const localSearchTerm = ref('');
const searchTerm = useVModel(props, 'filterString', emit);

const searchModel = computed({
  get() {
    return typeof searchTerm.value === 'string'
      ? searchTerm.value
      : localSearchTerm.value;
  },
  set(value: string) {
    if (typeof searchTerm.value === 'string') {
      searchTerm.value = value;
    } else {
      localSearchTerm.value = value;
    }
  },
});

const showInput = computed(() => {
  if (props.filter || props.creatable) {
    return true;
  }
  return typeof searchTerm.value === 'string';
});

const isOpen = ref(false);
watch(isOpen, (value) => {
  nextTick(() => {
    if (value && inputRef.value) {
      inputRef.value?.focus();
    }
  });
});

watch(searchModel, () => {
  if (focused.value && !isOpen.value) {
    isOpen.value = true;
  }
});

const { items, selectedItems, select, isSelected, transformItem } = useSelect({
  itemTitle: toRef(props, 'itemTitle'),
  itemValue: toRef(props, 'itemValue'),
  value: toRef(props, 'modelValue'),
  items: computed(() => props.options || []),
  multiple: toRef(props, 'multiple'),
  onUpdate: (value) => emit('update:modelValue', value),
  valueComparator: (a, b) => a === b,
  returnObject: toRef(props, 'returnObject'),
});

watch(focused, (value, oldValue) => {
  if (value || value === oldValue) {
    return;
  }
  isOpen.value = false;
  if (props.creatable && searchModel.value) {
    select(transformItem(searchModel.value));
  }
  searchModel.value = '';
});

const onKeydown = (e: KeyboardEvent) => {
  if (['Enter', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
    e.preventDefault();
  }

  if (['Enter', 'ArrowDown'].includes(e.key)) {
    isOpen.value = true;
  }
  if (['Escape'].includes(e.key)) {
    isOpen.value = false;
  }
  if (e.key === 'Enter' && searchModel.value && props.creatable) {
    select(transformItem(searchModel.value));
    searchModel.value = '';
  }
};

const filteredItems = computed(() => {
  const filter = normalizeFilter(props.filter, 'title');
  if (!filter || !searchModel.value?.trim()) {
    return items.value;
  }
  return items.value.filter((item) => filter(item, searchModel.value!));
});

const displayItems = computed(() => {
  if (props.hideSelected) {
    return filteredItems.value.filter(
      (filteredItem) =>
        !selectedItems.value.some((s) => s.value === filteredItem.value),
    );
  }
  return filteredItems.value;
});

const onSelect = (item: InternalItem) => {
  if (item.raw.disabled) {
    return;
  }
  select(item);
  if (!props.multiple) {
    searchModel.value = props.creatable ? item.title : '';
    isOpen.value = false;
  }
};
</script>

<template>
  <FieldDropdown
    :disabled="disabled"
    :focused="focused"
    v-model="isOpen"
    :tabindex="-1"
    @mousedown.prevent=""
  >
    <template #target>
      <KDropdownTarget
        :loading="loading"
        :withoutCaret="!Array.isArray(options) && !needMore"
        @keydown="onKeydown"
      >
        <div :class="$style.valueBlock">
          <span :class="$style.value">
            <span
              v-if="!showInput && placeholder && !selectedItems.length"
              :class="$style.placeholder"
            >
              {{ placeholder }}
            </span>
            <template v-else>
              <template v-if="multiple || !creatable || !searchModel">
                <template v-if="chips && selectedItems.length">
                  <KChip
                    v-for="item in selectedItems"
                    :key="item.value"
                    @close="onSelect(item)"
                    size="small"
                    label
                    closable
                    >{{ item.title }}</KChip
                  >
                </template>
                <template v-if="!chips">
                  {{ selectedItems.map((item) => item.title).join(', ') }}
                </template>
              </template>
            </template>
            <input
              ref="inputRef"
              type="text"
              v-model="searchModel"
              :placeholder="
                placeholder && !selectedItems.length ? placeholder : ''
              "
              :disabled="disabled"
              :style="{ opacity: showInput ? 1 : 0 }"
            />
          </span>
        </div>
      </KDropdownTarget>
    </template>
    <template #widget="{ getItemProps }">
      <div :class="$style.widget">
        <!--        <div-->
        <!--          v-if="showInput"-->
        <!--          :class="$style.filter"-->
        <!--        >-->
        <!--          <input-->
        <!--            ref="inputRef"-->
        <!--            :class="$style.filterInput"-->
        <!--            v-model="searchModel"-->
        <!--          />-->
        <!--        </div>-->
        <KList>
          <KListItem
            :active="isSelected(item.value)"
            :disabled="item.raw.disabled"
            v-for="item in displayItems"
            :key="`${item.value}__${item.title}`"
            @click="() => onSelect(item)"
            v-bind="getItemProps()"
            >{{ item.title }}
          </KListItem>
          <KListItem
            v-if="Array.isArray(options) && displayItems.length === 0"
            disabled
          >
            <span>{{ t('listField.empty') }}</span>
          </KListItem>
          <KListItem
            v-else-if="needMore"
            :disabled="loading"
            :class="$style.more"
            @click="() => emit('clickMore')"
          >
            <span>{{ t('listField.more') }}</span>
            <div
              v-if="loading"
              :class="$style.moreIcon"
            >
              <KLoading inherit />
            </div>
          </KListItem>
        </KList>
      </div>
    </template>
  </FieldDropdown>
</template>

<style module lang="scss">
.more {
  display: flex;
  color: #0e9aef;
  align-items: center;
  gap: 10px;
}

.moreIcon {
  width: 1.9em;
  aspect-ratio: 1/1;
}

.valueBlock {
  padding: 0 0 0 0.857em;
}

.value {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  :global(input) {
    border-style: none;
    min-width: 0;
    width: 100%;
    flex: 1 1;
    letter-spacing: inherit;
    outline: 0;

    color: inherit;
    padding: 0;
    margin: 0;
    box-shadow: none;
    background: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    touch-action: manipulation;

    &:disabled {
      cursor: inherit;
    }
  }
}

.placeholder {
  color: #999;
}

.widget {
  border: #e7eaec 1px solid;
  background: #fff;
  width: 100%;
}

.filter {
  width: auto;
  margin: 4px;
  height: 30px;
  line-height: 30px;
  border: #ccc 1px solid;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #555555;
  padding-left: 0.857em;
}

.filterInput {
  position: relative;
  border: none;
  outline: 0;
  width: 100%;
  height: 100%;

  color: inherit;
  padding: 0;
  margin: 0;
  box-shadow: none;
  background: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  touch-action: manipulation;
}
</style>
