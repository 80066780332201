import { createRouter, createWebHistory } from 'vue-router';
import PageLayout from '@/routing/PageLayout.vue';
import config from '@/shared/configs/config';
import { Route } from '@/shared/contracts/route';
import getEnv from '@/shared/getEnv';

type Breadcrumb = {
  name: Route;
};
declare module 'vue-router' {
  interface RouteMeta {
    breadcrumbs?: Breadcrumb[];
    customLayout?: boolean;
  }
}

const breadcrumbs = (names: Array<Route | Breadcrumb>) => {
  return names.map((item) => {
    if (typeof item === 'string') {
      return {
        name: item,
      };
    }
    return item;
  });
};

const env = getEnv();
const router = createRouter({
  history: createWebHistory(config.defaultRoute),
  routes: [
    {
      path: '',
      component: PageLayout,
      children: [
        {
          name: Route.fairspin,
          path: 'widget/fairspin',
          component: () => import('@/view/FairspinView.vue'),
        },
        {
          name: Route.goals,
          path: 'offers/goals',
          component: () => import('@/view/GoalsView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([Route.dashboard, Route.goals]),
          },
        },
        {
          path: '/offers/offer/:id',
          redirect: { name: Route.offer },
          component: () => import('@/view/OfferLayout.vue'),
          meta: {
            customLayout: true,
            breadcrumbs: breadcrumbs([
              Route.dashboard,
              Route.goals,
              Route.offer,
            ]),
          },
          children: [
            { path: 'promo', redirect: 'promo/links' },
            {
              name: Route.offer,
              path: 'promo/links',
              component: () => import('@/view/OfferPromoLinksView.vue'),
            },
            {
              name: Route.offerPromoBanners,
              path: 'promo/banners',
              component: () => import('@/view/OfferPromoBannersView.vue'),
            },
            {
              name: Route.offerGoals,
              path: 'goals',
              component: () => import('@/view/OfferGoalsView.vue'),
            },
            {
              name: Route.offerDescription,
              path: 'description',
              component: () => import('@/view/OfferDescriptionView.vue'),
            },
          ],
        },
        {
          name: Route.report,
          path: 'statistics/report',
          component: () => import('@/view/StatisticsReportView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([Route.dashboard, Route.report]),
          },
        },
        {
          name: Route.conversions,
          path: 'statistics/conversions',
          component: () => import('@/view/ConversionsView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([Route.dashboard, Route.conversions]),
          },
        },
        {
          name: Route.conversion,
          path: 'statistics/conversions/:id',
          component: () => import('@/view/ConversionsItemView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([
              Route.dashboard,
              Route.conversions,
              Route.conversion,
            ]),
          },
        },
        {
          name: Route.payment,
          path: 'office/finances/payment',
          component: () => import('@/view/PaymentView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([Route.dashboard, Route.payment]),
          },
        },
        {
          name: Route.payout,
          path: 'office/finances/payout',
          component: () => import('@/view/PayoutsView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([Route.dashboard, Route.payout]),
          },
        },
        {
          name: Route.payoutItem,
          path: 'office/finances/payout/:id',
          component: () => import('@/view/PayoutsItemView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([
              Route.dashboard,
              Route.payout,
              Route.payoutItem,
            ]),
          },
        },
        {
          name: Route.historyFinance,
          path: 'office/finances/history',
          component: () => import('@/view/FinanceHistoryView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([Route.dashboard, Route.historyFinance]),
          },
        },
        {
          name: Route.financeCurrency,
          path: 'office/finances/currency',
          component: () => import('@/view/FinanceCurrencyView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([Route.dashboard, Route.financeCurrency]),
          },
        },
        {
          name: Route.balance,
          path: 'office/finances/balance',
          component: () => import('@/view/BalanceView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([Route.dashboard, Route.balance]),
          },
        },
        {
          name: Route.postbacks,
          path: 'postback/list',
          component: () => import('@/view/PostbacksView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([Route.dashboard, Route.postbacks]),
          },
        },
        {
          name: Route.historyPostback,
          path: 'postback/history',
          component: () => import('@/view/PostbackHistoryView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([
              Route.dashboard,
              Route.postbacks,
              Route.historyPostback,
            ]),
          },
        },
        {
          name: Route.createPostback,
          path: 'postback/create',
          component: () => import('@/view/PostbackCreateView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([
              Route.dashboard,
              Route.postbacks,
              Route.createPostback,
            ]),
          },
        },
        {
          name: Route.editPostback,
          path: 'postback/edit/:id/:test(test)?',
          component: () => import('@/view/PostbackEditView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([
              Route.dashboard,
              Route.postbacks,
              Route.editPostback,
            ]),
          },
        },
        {
          name: Route.profile,
          path: 'office/settings/profile',
          component: () => import('@/view/ProfileView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([Route.dashboard, Route.profile]),
          },
        },
        {
          name: Route.security,
          path: 'office/settings/security',
          component: () => import('@/view/SecurityView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([Route.dashboard, Route.security]),
          },
        },
        {
          name: Route.information,
          path: 'office/settings/information',
          component: () => import('@/view/InformationView.vue'),
          meta: {
            breadcrumbs: breadcrumbs([Route.dashboard, Route.information]),
          },
        },
        {
          name: Route.dashboard,
          path: '',
          component: () => import('@/view/DashboardView.vue'),
        },
      ],
    },
    // { path: '/500', component: () => null },
    // { path: '/401', component: () => null },
    // { path: '/health', component: () => null },
    {
      name: 'not-found',
      path: '/:catchAll(.*)',
      component: () => import('@/view/NotFoundView.vue'),
    },
  ],
});

export default router;
