<script setup lang="ts">
import { computed } from 'vue';
import ChangeTimezone from '@/feature/change-timezone/ChangeTimezone.vue';
import ChangeLocale from '@/widgets/header/ui/ChangeLocale.vue';
import WUserHeaderPanel from '@/widgets/user-header-panel/WUserHeaderPanel.vue';
import { useSession } from '@/shared/compositions/useSession';
import { useTranslation } from '@/shared/compositions/useTranslation';
import { useUser } from '@/shared/compositions/user/useUser';
import config from '@/shared/configs/config';
import getEnv from '@/shared/getEnv';
import { useSidebarStore } from '@/shared/stores/sidebarStore';

const { cpaUrl } = getEnv();
const { t } = useTranslation('common');
const sidebarStore = useSidebarStore();
const { data } = useUser();
const session = useSession();

const exitLinkProp = computed(() => {
  if (!data.impersonating && !data.isAdmin) {
    return null;
  }
  if (data.impersonating) {
    return {
      href:
        cpaUrl +
        config.static.exitSwitch.replace('{pid}', data.pid?.toString() || ''),
      title: t('user.returnTo', { name: data.impersonating }),
      onClick: () => {
        session.removeAccessData();
      },
    };
  }
  return {
    href: cpaUrl + '/admin',
    title: t('user.adminPanel'),
  };
});
</script>

<template>
  <header class="top-header">
    <a
      :class="[
        'top-header__menu-toggle btn btn-primary',
        {
          active: sidebarStore.isOpen,
        },
      ]"
      @click="sidebarStore.toggle"
    >
      <span />
    </a>
    <div class="top-header__left">
      <ChangeTimezone />
    </div>
    <div class="top-header__right">
      <div
        v-if="exitLinkProp"
        class="top-header__action"
      >
        <a
          class="btn btn-primary top-header__admin"
          v-bind="exitLinkProp"
        >
          <i class="fa fa-cogs hidden-md hidden-lg" />
          <span class="hidden-xs hidden-sm">{{ exitLinkProp.title }}</span>
        </a>
      </div>
      <ChangeLocale />
      <WUserHeaderPanel />
    </div>
  </header>
</template>
