import { useMutation } from '@tanstack/vue-query';
import { useTranslation } from 'i18next-vue';
import { intersection } from 'lodash';
import type { PartialDeep } from 'type-fest';
import { computed, inject, reactive } from 'vue';
import { updateUser } from '@/shared/api/user';
import { useHttp } from '@/shared/compositions/useHttp';
import {
  impersonatingInjectKey,
  userInjectKey,
} from '@/shared/compositions/user/const';
import type { UserResponseDto } from '@/shared/contracts/dto/UserResponseDto';
import { isJunket } from '@/shared/utils/user';

export const useUser = () => {
  const { i18next } = useTranslation();
  const http = useHttp();

  const user = inject(userInjectKey)!;
  const impersonating = inject(impersonatingInjectKey);

  const { mutateAsync } = useMutation({
    mutationFn: (data: PartialDeep<UserResponseDto>) =>
      http.request(updateUser(data), {
        handleStatus: false,
        secure: 'private',
      }),
  });

  const { data, isLoading, refetch } = user;

  const update = async (data: PartialDeep<UserResponseDto>) => {
    return mutateAsync(data).then((response) => {
      if (response && response.data === 1) {
        refetch();
      }
      return response;
    });
  };
  const changeLocale = (locale: string) => {
    // instant lang data
    i18next.changeLanguage(locale);
    update({
      locale,
    });
  };

  return {
    isLoading,
    actions: {
      update,
      refetch,
      changeLocale,
    },
    // raw data
    entity: reactive(data),
    // handy data
    data: reactive({
      timezone: computed(() => data.value?.timezone),
      // instant lang data
      locale: computed(() => i18next.language),
      email: computed(() => data.value?.email),
      roles: computed(() => data.value?.roles),
      impersonating: computed(() => impersonating?.value),
      isAdmin: computed(
        () =>
          intersection(data.value?.roles || [], [
            'ROLE_ADMIN',
            'ROLE_SUPER_ADMIN',
            'ROLE_AFFILIATE_MANAGER',
            'ROLE_MEDIA_BUYER',
            'ROLE_SUPPORT',
          ]).length,
      ),
      isJunket: computed(() => data.value && isJunket(data.value)),
      accountsList: computed(() => data.value?.publisher?.all_accounts),
      currency: computed(() => data.value?.publisher?.account.balance.currency),
      pid: computed(() => data.value?.publisher?.id),

      holdBalance: computed(() => data.value?.publisher?.account.hold_balance),
      summaryBalance: computed(() =>
        data.value?.publisher?.all_accounts
          .filter(
            (account) =>
              parseFloat(account.balance.amount) > 0 &&
              account.type.startsWith('HR') === false,
          )
          .reduce(
            (acc, account) =>
              Number(account.balance.amount.replace(',', '.')) + Number(acc),
            0,
          ),
      ),
      balanceList: computed(() => {
        const accounts = data.value?.publisher?.all_accounts;
        if (!accounts) {
          return [];
        }
        const amountMap = new Map<string, number>();
        accounts.forEach((account) => {
          if (!amountMap.has(account.type)) {
            amountMap.set(account.type, 0);
          }

          amountMap.set(
            account.type,
            Number(amountMap.get(account.type)) +
              Number(account.balance.amount.replace(',', '.')),
          );
        });

        return Array.from(amountMap.entries()).map(([type, amount]) => ({
          type,
          amount,
        }));
      }),
    }),
  };
};
