<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useTranslation } from '@/shared/compositions/useTranslation';
import { Route } from '@/shared/contracts/route';

const props = defineProps<{
  pageName?: string;
}>();
const { t } = useTranslation('common');
const route = useRoute();
const pageName = computed(() => props.pageName || (route.name as Route));
</script>

<template>
  <div class="page-heading">
    <div class="page-heading__inner">
      <h2>{{ t(`page.${pageName}.header`) }}</h2>
      <slot name="breadcrumbs" />
    </div>
  </div>
  <section class="wrapper-content">
    <slot />
  </section>
</template>

<style scoped lang="scss"></style>
