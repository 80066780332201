import App from './App.vue';
import { i18n } from './i18next';
import {
  QueryCache,
  VueQueryPlugin,
  type VueQueryPluginOptions,
} from '@tanstack/vue-query';
import { createHead } from '@vueuse/head';
import 'bootstrap/less/bootstrap.less';
import I18NextVue from 'i18next-vue';
import { createPinia } from 'pinia';
import 'v-calendar/style.css';
import { createApp } from 'vue';
import { createVfm } from 'vue-final-modal';
import 'vue-final-modal/style.css';
import Toast, {
  type PluginOptions,
  POSITION,
  useToast,
} from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { createDependencies } from '@/app/dependencies';
import router from '@/routing/router';
import '@/shared/assets/styles/main.less';
import { appContext } from '@/shared/plugins/appContext';

interface QueryMeta extends Record<string, unknown> {
  errorHandler?: 'commonErrorOnly' | 'skip';
}

declare module '@tanstack/vue-query' {
  interface Register {
    queryMeta: QueryMeta;
    mutationMeta: QueryMeta;
  }
}

i18n.init();

const toastOption: PluginOptions = {
  position: POSITION.TOP_CENTER,
};
const app = createApp(App);

app.use(appContext, {
  dependencies: createDependencies(),
});
app.use(createHead());
app.use(createPinia());
app.use(createVfm());
app.use(Toast, toastOption);
const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    queryCache: new QueryCache({
      onError(error, query) {
        const errorHandler = query.meta?.errorHandler ?? 'commonErrorOnly';
        const toast = useToast();
        switch (errorHandler) {
          case 'commonErrorOnly': {
            toast.error('Some error happened while getting data');
            break;
          }
          case 'skip': {
            break;
          }
        }
      },
    }),
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  },
};
app.use(VueQueryPlugin, vueQueryPluginOptions);
app.use(I18NextVue, {
  i18next: i18n,
});
app.use(router);

export { app };
